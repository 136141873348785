@import "../../styles/utilities/variables";
@import "../../styles/utilities/mixins";

.home-page {
  min-width: 100vw;
  @include adaptTo("not-mobile") {
    max-width: 400px;
    margin: 0 auto;
  }

  &__imgbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }
  &__img {
    width: 100vw;
    @include adaptTo("not-mobile") {
      width: 500px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    @include adaptTo("not-mobile") {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      padding: 0px 30px;
      margin: -1px 0px;
    }
  }

  &__title {
    color: $font-color-light;
    background-color: $background-brand;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    padding: 10px;
    margin-top: -5px;
    width: 100vw;
    @include adaptTo("not-mobile") {
      color: $font-color-dark;
      text-align: left;
      font-weight: 800;
      font-size: 45px;
      width: 450px;
      padding: 10px;
      margin-left: 10;
      background-color: $background-generic;
      max-width: 900px;
    }
  }

  &__text {
    color: $font-color-dark;
    font-weight: 400;
    text-align: center;
    font-size: 19px;
    padding: 20px;

    @include adaptTo("not-mobile") {
      text-align: center;
      color: $font-color-light;
      background-color: $background-brand;
      font-size: 22px;
    }
  }

  &__button {
    margin-top: 30px;
    margin-bottom: 50px;
    @include adaptTo("not-mobile") {
      margin-top: 90px;
    }
  }

  &__container {
    min-height: calc(100vh - 162px);
    margin: 0 auto;
  }

  &__logo {
    margin: 0 auto;
    align-items: center;
    padding: 20px;
    justify-content: center;
    width: 180px;
  }
}
