// Colores de backgrounds
$background-generic: #F9F9FB;
$background-brand: #199bf6;
$background-buttons: #0469da;
$background-gray: #e8e8e8;
$background-mark: #eaf5fd;

// Colores de fuentes
$font-color-dark: black;
$font-color-light: #ffff;
$font-color-alert: #ce1d1d;
$font-color-gray: #6c6c6c;
$font-color-scores: #747474;
$font-color-brand: #199bf6;

// Colores miscelanea
