@import "../../styles/utilities/variables";
@import "../../styles/utilities/mixins";

.quizz-page {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0px auto;
  overflow: hidden;
  // background-color: red;

  &__container {
    max-width: 1000px;
    display: flex;
    margin: 30px;
    align-items: center;
    margin: 0px auto;
    overflow: auto;
    min-height: fit-content;
    max-height: 100vh;
    // background-color: blue;
  }

  &__progress {
    max-width: 1000px;
    width: 100%;
    max-height: 100px;
  }

  &__formulary {
    position: relative;
    padding: 10px;
    min-height: calc(100vh);
    @include adaptTo("not-mobile") {
      padding-top: 50px;
    }
  }

  &__navigation {
    position: sticky;
    bottom: 0px;
    min-width: 360;
    width: 100%;
    max-width: 800px;
    max-height: 105px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    bottom: 0px;
    user-select: none;
  }

  &__next {
    color: $font-color-light;
    background-color: $font-color-brand;
    border-radius: 30px;
    padding: 30px;
    border: 2px solid $font-color-brand;
    font-weight: 400;
    width: 48%;
    margin: 10px;
    cursor: pointer;

    &:hover {
      background-color: $background-buttons;
    }
  }

  &__previous {
    color: $font-color-brand;
    border-radius: 30px;
    padding: 30px;
    border: 2px solid $font-color-brand;
    font-weight: 400;
    width: 45%;
    margin: 10px;
    cursor: pointer;

    &:hover {
      background-color: $background-gray;
    }

    &-hidden {
      color: transparent;
      border-radius: 30px;
      padding: 30px;
      border: 2px solid transparent;
      font-weight: 400;
      width: 45%;
      margin: 10px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &__icon {
    position: absolute;
    background-color: red;

    &--left {
      left: 0px;
    }
  }

  &__ball {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  &__ball div {
    box-sizing: content-box;
  }

  &__textarea {
    border-radius: 10px;
    outline: 2px dotted lightgray;
    border: 0;
    background-color: white;
    padding: 10px 1rem;
    width: 700px;
    max-width: 85vw;
    // @include adaptTo("not-mobile") {
    //  max-width: 700px;
    // }
    min-height: 140px;
    max-height: 400px;
    margin: 0px auto;
    transition: 0.2s;
    resize: none;

    &:focus {
      outline: 3px solid #199bf6;
      outline-offset: 5px;
      background-color: #fff;
      z-index: 1;
    }
  }

  @keyframes bouncing {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.45, 0, 0.9, 0.55);
    }
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 108px);
      animation-timing-function: cubic-bezier(0, 0.45, 0.55, 0.9);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}
